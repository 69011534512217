import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2'
import './Permisos.css'
import { TableVirtuoso } from 'react-virtuoso';
import dayjs from 'dayjs';
import { useHistory } from "react-router-dom";

function CancelaCambiosFijos() {
  let history = useHistory();
  let [loading, setLoading] = useState(true)
  let [cancelaCambiosOG, setCancelaCambiosOG] = useState([])
  let [cancelaCambios, setCancelaCambios] = useState([])
  let [rutas, setRutas] = useState([])
  let [ruta, setRuta] = useState(null)
  let [botonRuta, setBotonRuta] = useState('Selecciona una ruta')
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [tituloBoton1, setTituloBoton1] = useState("Status")
  let [tituloBoton2, setTituloBoton2] = useState("Dia")
  let [searchText, setSearchText] = useState("")
  let dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes"]

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getCancelaCambios() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllCancelaCambioFijo`)
      .then((response) => { setCancelaCambios(response.data.data.sort((a, b) => a.fecha.localeCompare(b.fecha)).filter((item) => dayjs(item.fecha).isAfter(dayjs().subtract(1, 'day')))); setCancelaCambiosOG(response.data.data.sort((a, b) => (a.dia > b.dia) ? 1 : -1).filter((item) => dayjs(item.fecha).isAfter(dayjs().subtract(1, 'day')))); setLoading(false) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllRutas`)
      .then((response) => { setRutas(response.data.data.sort((a, b) => a.numero - b.numero)); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  // filter records by search text and option
  const filterData = (value, filAcumulado) => {

    let temp = []

    let resultadoFiltrado = cancelaCambiosOG.filter((item) => {

      for (let key in filAcumulado) {
        if (item[key] === undefined || item[key] != filAcumulado[key])
          return false;
      }
      return true;
    });

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : "";

    if (lowercasedValue === "") { setCancelaCambios(temp) }
    else {
      const filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setCancelaCambios(filteredData);
    }
  };

  function cambiarStatus(id, status, cambio, cancelar) {

    axios.patch(`${process.env.REACT_APP_BackURL}/updateCancelaCambioFijo?id=${id}`, { status: status }) //cambia el status del permiso
      .then((res) => {

        let cancelaCambiosTemp = [...cancelaCambios]
        let cancelaCambiosOGTemp = [...cancelaCambiosOG]

        let index = cancelaCambiosTemp.findIndex((item) => item._id == res.data.data._id)

        cancelaCambiosTemp[index].status = res.data.data.status

        setCancelaCambios(cancelaCambiosTemp)
        setCancelaCambiosOG(cancelaCambiosOGTemp)

      })
      .catch((err) => { console.log(err) })

  }

  function eliminarCambioFijo(id) {
    axios.delete(`${process.env.REACT_APP_BackURL}/deleteCancelaCambioFijoById?id=${id}`)
      .then((res) => {
        let cancelaCambiosTemp = [...cancelaCambios]
        let cancelaCambiosOGTemp = [...cancelaCambiosOG]

        let index = cancelaCambiosTemp.findIndex((item) => item._id == id)

        cancelaCambiosTemp.splice(index, 1)

        setCancelaCambios(cancelaCambiosTemp)
        setCancelaCambiosOG(cancelaCambiosOGTemp)

      })
      .catch((err) => { console.log(err) })
  }

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  function existe(key, value) {
    let siHay = false

    cancelaCambios.forEach((alumno) => {
      if (alumno[key] == value) { siHay = true }
    })

    return siHay
  }

  function filtroActivo(obj, key) {
    return key in obj
  }

  function cruzaRutas() {
    axios.post(`${process.env.REACT_APP_BackURL}/revisaPermisosManual`, { ida: false, regreso: true })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Cambios aplicados',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((err) => { console.log(err) })
  }

  useLayoutEffect(() => {
    getCancelaCambios()
  }, [])

  useEffect(() => {
    filterData(searchText, filtroAcumulado)
  }, [searchText, filtroAcumulado])

  return (
    <>

      <div className='centro'>

        <br />

        <div className='botones'>
          <CDropdown className='p-1'>

            <CDropdownToggle color={filtroActivo(filtroAcumulado, "fecha") ? "info" : "secondary"}>{tituloBoton2}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              {
                !filtroActivo(filtroAcumulado, "fecha") ? null :
                  <CDropdownItem onClick={() => { eliminarFiltroAcumulado("fecha"); setTituloBoton2("Fecha") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
              }

              {
                [...new Set(cancelaCambiosOG.map((item) => item.fecha))].map((fecha, i) => (

                  <CDropdownItem key={i} onClick={() => { filtrarAcumulado("fecha", fecha); setTituloBoton2(`${fecha}`) }} className={!existe("fecha", fecha) ? "noHay" : "siHay"}>{fecha}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CDropdown className='p-1'>

            <CDropdownToggle color={filtroActivo(filtroAcumulado, "status") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              {
                !filtroActivo(filtroAcumulado, "status") ? null :
                  <CDropdownItem onClick={() => { eliminarFiltroAcumulado("status"); setTituloBoton1("Status") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
              }
              {
                [...new Set(cancelaCambiosOG.map((item) => item.status))].map((status, i) => (

                  <CDropdownItem key={i} onClick={() => { filtrarAcumulado("status", status); setTituloBoton1(`${status ? "Autorizado" : "No autorizado"} camión`) }} className={!existe("status", status) ? "noHay" : "siHay"}>{status ? "Autorizado" : "No autorizado"}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CButton color='danger' onClick={() => { setFiltroAcumulado({}) }}>Reset filtros</CButton>

          <CButton onClick={() => history.push("/imprimir?tipo=cancelados", { data: cancelaCambios.filter((item) => item.fecha == dayjs().format('YYYY-MM-DD')) })}>Imprimir hoy</CButton>

          <h5>{cancelaCambios.length} cambios registrados</h5>

        </div>
        <br />

        {
          !loading ?
            cancelaCambios.length == 0 ?
              <center><h1>No hay cancelaciones de cambios registrados</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={cancelaCambios}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Fecha / Tipo / Status</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Día</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Alumno / Codigo</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>A casa de:</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisosMediano'>Dirección Destino</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Autorizar</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell color={!item.status ? "light" : null} className='cellsPermisos'>
                          <React.Fragment>
                            {dayjs(item.fecha).format('DD/MM/YYYY')}
                            <br />
                            {item.cambioFijoID.tipo == "direccionTemporal" ? "Direccion temporal" : "papaRecoge"}
                            <br />
                            {item.status ? 'Autorizado' : 'No autorizado'}
                          </React.Fragment>
                        </CTableDataCell>
                        <CTableDataCell color={!item.status ? "light" : null} className='cells'>{dias[item.cambioFijoID.dia]}</CTableDataCell>
                        <CTableDataCell color={!item.status ? "light" : null} className='cellsPermisos'>
                          <React.Fragment>
                            {item.cambioFijoID.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                            <br />
                            {item.cambioFijoID.alumnoInvitadoID.student_code}
                          </React.Fragment>
                        </CTableDataCell>
                        <CTableDataCell color={!item.status ? "light" : null} className='cellsPermisos'>{item.cambioFijoID.tipo == "direccionTemporal" ? item.cambioFijoID.direccionNombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : "No aplica"}</CTableDataCell>
                        <CTableDataCell color={!item.status ? "light" : null} className='cellsPermisosMediano'>{item.cambioFijoID.tipo == "direccionTemporal" ? item.cambioFijoID.direccionDestino.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : "No aplica"}</CTableDataCell>
                        <CTableDataCell color={!item.status ? "light" : null} className='cellsPermisos'>
                          <CButton disabled={item.status ? true : false} color="success" onClick={() => { cambiarStatus(item._id, true, item, false) }}><i className="fa-solid fa-check"></i></CButton>
                          <CButton disabled={!item.status ? true : false} color="danger" onClick={() => { cambiarStatus(item._id, false, item, false) }}><i className="fa-solid fa-x"></i></CButton>
                          {/* boton eliminar */}
                          <CButton color="warning" onClick={() => { eliminarCambioFijo(item._id) }}><i className="fa-solid fa-trash"></i></CButton>
                        </CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div >
    </>
  )
}

export default CancelaCambiosFijos
