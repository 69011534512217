import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilHappy, cilLibrary, cilGroup, cilPeople, cilDescription, cilUserFemale, cilMap, cilCalendar, cilLocationPin, cilBellExclamation, cilSpeedometer } from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Alumnos',
    to: '/alumnos',
    icon: <CIcon icon={cilHappy} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Padres',
    to: '/padres',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Familias',
    to: '/familias',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Prefectas',
    to: '/prefectas',
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  // {
  //   component: CNavItem,
  //   name: 'Camiones',
  //   to: '/camiones',
  //   icon: <CIcon icon={cilBusAlt} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     // text: 'NEW',
  //   },
  // },
  {
    component: CNavItem,
    name: 'Rutas fijas',
    to: '/rutas',
    icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Rutas del día',
    to: '/historialrutas',
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Enviar notificaciones',
    to: '/notificaciones',
    icon: <CIcon icon={cilBellExclamation} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Dias sólo hebreo',
    to: '/diashebreo',
    icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Cambios fijos',
    to: '/cambiosfijos',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Cancela cambios fijos',
    to: '/cancelacambiosfijos',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Cambios por viaje',
    to: '/permisosViaje',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Cambios temporales',
    to: '/permisos',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Papelitos cambios fijos',
    to: '/cambioshoy',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Papelitos recogen fijos',
    to: '/papaRecogeFijo',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Papelitos cambios',
    to: '/permisoshoy',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Papelitos recogen',
    to: '/papaRecogeHoy',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Reportes',
    to: '/reportes',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Reportes archivados',
    to: '/archivoreportes',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  // {
  //   component: CNavItem,
  //   name: 'Actualizar Datos',
  //   to: '/datosactualizar',
  //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     // text: 'NEW',
  //   },
  // },
  {
    component: CNavItem,
    name: 'Usuarios',
    to: '/usuarios',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },

]

export default _nav
