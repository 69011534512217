import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import { useHistory } from "react-router-dom";
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import axios from 'axios'
import dayjs from 'dayjs'
import "react-datepicker/dist/react-datepicker.css";
import './Rutas.css'

function PermisosHoy() {
  let history = useHistory();
  let [loading, setLoading] = useState(true)
  let [permisosHoy, setPermisosHoy] = useState([]);
  let [permisosHoyOG, setPermisosHoyOG] = useState([]);
  let [selectedDate, setSelectedDate] = useState(new Date());
  let [tituloBoton1, setTituloBoton1] = useState("Filtrar por sección")
  let [tituloBoton2, setTituloBoton2] = useState("Filtrar por dirección")
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [filtro, setFiltro] = useState('') // este estado es apra filtar por el buscador si se usa en el futuro

  function getPermisosHoy() {
    axios.get(`${process.env.REACT_APP_BackURL}/getPermisosByDate?dia=${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}&tipo=permisos`)
      .then((response) => { setPermisosHoy(response.data.data); setLoading(false); setPermisosHoyOG(response.data.data) })
      .catch((err) => { console.log(err) })
  }

  function exportarExcel() {
    let datos = []
    // let cantidad = alumnos.map((v) => v.cantidad)
    // let createdAt = alumnos.map((v) => v.createdAt)
    // let donador = alumnos.map((v) => v.donadorID.nombreCompleto)
    // let vendedor = alumnos.map((v) => v.abrejID.nombre)
    // let stripeOpaypal = alumnos.map((v) => v.stripeOpaypal)

    // for (var i = 0; i < cantidad.length; i++) {
    //   datos.push({
    //     'Cantidad': `$${cantidad[i]}`,
    //     'Desde': new Date(createdAt[i]).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }),
    //     'Donador': donador[i],
    //     'Vendedor': vendedor[i],
    //     'Stripe / PayPal': stripeOpaypal[i],
    //   });
    // }

    // exportFromJSON({ data: datos, fileName: "Reporte_Padres", exportType: "csv" })
  }

  function getPapaRecogeByDate(date) {

    let fecha = String(date)

    fecha = `${fecha.split('/')[0]}-${fecha.split('/')[1].toString().padStart(2, '0')}-${fecha.split('/')[2].toString().padStart(2, '0')}`

    setLoading(true)

    axios.get(`${process.env.REACT_APP_BackURL}/getPermisosByDate?dia=${fecha}`)
      .then((response) => { setPermisosHoy(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  function filterData(value, filAcumulado) {

    let temp = []

    let resultadoFiltrado = permisosHoyOG.filter((item) => {

      for (let key in filAcumulado) {
        if (key == "direccion") {
          if (item === undefined || item.idRutaDestino.direccionRuta !== filAcumulado[key]) {
            return false
          }
        } else if (key == "seccion") {
          if (item === undefined || item.alumnoInvitadoID.academic_section !== filAcumulado[key]) {
            return false
          }
        } else {
          if (item[key] === undefined || item[key] !== filAcumulado[key]) {
            return false
          }
        }
      }
      return true
    })

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase() : '';

    if (lowercasedValue === '') { setPermisosHoy(temp) }
    else {
      const filteredData = temp.filter(item => {
        return Object.keys(item).some(key =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setPermisosHoy(filteredData)
    }
  };

  function filtrarAcumulado(key, value) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: value })
  }

  function eliminarFiltroAcumulado(key) {
    let temp = { ...filtroAcumulado }
    delete temp[key]
    setFiltroAcumulado(temp)
  }

  function filtroActivo(filtroAcumulado, key) {
    return key in filtroAcumulado
  }

  useLayoutEffect(() => {
    getPermisosHoy()
  }, [])

  useEffect(() => {
    filterData(filtro, filtroAcumulado)
  }, [filtroAcumulado])

  return (
    <>
      <div className='centro'>

        <center>

          {/* <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} /> */}

          <br />

          <DatePicker dateFormat="dd/MM/yyyy" selected={selectedDate} onChange={(date) => { setSelectedDate(date); getPapaRecogeByDate(`${new Date(date).getFullYear()}/${new Date(date).getMonth() + 1}/${new Date(date).getDate()}`) }} />

        </center>

        <br />

        <div className='botones'>
          {/* <CDropdown className='p-1'>

            <CDropdownToggle color="info">{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              <CDropdownItem onClick={() => { setPermisosHoy(permisosHoyOG); setTituloBoton1("Filtrar por sección") }} style={{ color: "green" }}>Todos</CDropdownItem>

              {
                [...new Set(permisosHoyOG.map((item) => item.alumnoInvitadoID.academic_section))].map((seccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filterData(seccion); setTituloBoton1(`${seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}`) }} className='siHay' >{seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CDropdown className='p-1'>

            <CDropdownToggle color="info">{tituloBoton2}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              <CDropdownItem onClick={() => { setPermisosHoy(permisosHoyOG); setTituloBoton2("Filtrar por Direccion") }} style={{ color: "green" }}>Todos</CDropdownItem>

              {
                [...new Set(permisosHoyOG.map((item) => item.deIda))].map((direccion, i) => (

                  <CDropdownItem key={i} onClick={() => { setPermisosHoy(permisosHoyOG.filter((item) => item.deIda == direccion)); setTituloBoton2(`${direccion ? 'Ida' : 'Regreso'}`) }} className='siHay' >{direccion ? 'Ida' : 'Regreso'}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown> */}

          {/* dropdown filtro seccion */}

          <CDropdown className='p-1'>

            <CDropdownToggle color={filtroActivo(filtroAcumulado, "seccion") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              {
                !filtroActivo(filtroAcumulado, "seccion") ? null :
                  <CDropdownItem onClick={() => { eliminarFiltroAcumulado("seccion"); setTituloBoton1("Filtrar por sección") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
              }

              {
                [...new Set(permisosHoyOG.map((item) => item.alumnoInvitadoID.academic_section))].map((seccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filtrarAcumulado("seccion", seccion); setTituloBoton1(`${seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}`) }} className={"siHay"}>{seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CDropdown className='p-1'>

            <CDropdownToggle color={filtroActivo(filtroAcumulado, "direccion") ? "info" : "secondary"}>{tituloBoton2}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              {
                !filtroActivo(filtroAcumulado, "direccion") ? null :
                  <CDropdownItem onClick={() => { eliminarFiltroAcumulado("direccion"); setTituloBoton2("Filtrar por dirección") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
              }

              {
                [...new Set(permisosHoyOG.map((item) => item.idRutaDestino.direccionRuta))].map((direccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filtrarAcumulado("direccion", direccion); setTituloBoton2(`${direccion}`) }} className={"siHay"}>{direccion}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CButton onClick={() => history.push("/imprimir?tipo=direccionTemporal", { data: permisosHoy })}>Imprimir</CButton >
          <CButton onClick={() => history.push(`/imprimirLista?tipo=permisos&fecha=${selectedDate}&filtro=${tituloBoton1}`, { data: permisosHoy })}>Imprimir lista</CButton >
          <h5>{permisosHoy.length > 0 ? `${permisosHoy.length} permiso${permisosHoy.length == 1 ? '' : 's'} autorizado${permisosHoy.length == 1 ? '' : 's'}` : 'Hoy no hay permisos para imprimir'}</h5>
        </div>

        <br />

        {
          !loading ?
            <>

              <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Fecha</CTableHeaderCell>
                    <CTableHeaderCell>Nombre</CTableHeaderCell>
                    <CTableHeaderCell>Grado</CTableHeaderCell>
                    <CTableHeaderCell>Sección</CTableHeaderCell>
                    <CTableHeaderCell>Escuela</CTableHeaderCell>
                    <CTableHeaderCell>En ruta</CTableHeaderCell>
                    <CTableHeaderCell>Direccion</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    permisosHoy.length > 0 ?
                      <>
                        {
                          permisosHoy.map((item, i) => {
                            return (
                              <CTableRow key={i}>
                                <CTableDataCell>{dayjs(item.fecha).format('DD/MM/YYYY')}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())} {item.alumnoInvitadoID.group_code.split('-')[2]}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.idRutaDestino.numero} {item.idRutaDestino.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.idRutaDestino.direccionRuta}</CTableDataCell>
                              </CTableRow>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="8">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default PermisosHoy
