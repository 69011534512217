import React from 'react'
import { CButton } from '@coreui/react';
import { useHistory } from "react-router-dom";
import './Imprimir.css'
import CIcon from '@coreui/icons-react'
import { cilArrowLeft } from '@coreui/icons';
import busYkt from '../assets/logo.jpg'
import yeshiba from '../assets/logo1.jpg'

function Imprimir() {
    const history = useHistory();
    let data = history.location.state.data;
    const query = new URLSearchParams(location.search)
    let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agost', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    let dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']

    return (
        <>

            <div className='botonImprimir sticky noprint'>
                <CButton onClick={() => history.goBack()}><CIcon icon={cilArrowLeft} /> Atras</CButton>
                <CButton onClick={() => window.print()}>Imprimir</CButton>
            </div>

            <br />

            {
                data.length == 0 ?
                    <center>
                        <br />
                        <h1>No hay nada para imprimir</h1>
                    </center>
                    :

                    query.get('tipo') == 'cancelados' ?

                        data.map((item, index) => (
                            <div key={index}>

                                <div className='head'>

                                    <div key={index} className='print etiqueta'>

                                        <div className='head'>
                                            <img width={'7%'} src={busYkt} alt="" />
                                            <div className='columna titulos'>YESHIVA KETER TORA</div>
                                            {query.get('tipo') == 'cancelados' ? <div className='columna titulos'>Cancelación de cambio fijo</div> : null}
                                            <img width={'7%'} src={yeshiba} alt="" />
                                        </div>

                                        <div className='fila'>
                                            <div className='columna titulos'>Alumno: {item.cambioFijoID.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} </div>
                                            <div className='columna titulos'>Grado: {`${item.cambioFijoID.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} ${item.cambioFijoID.alumnoInvitadoID.group_code.split('-')[2]}`}</div>
                                        </div>

                                        <div className='fila'>
                                            <div className='columna titulos'>{`${item.fecha.split('-')[2]} de ${meses[item.fecha.split('-')[1] - 1]} de ${item.fecha.split('-')[0]}`}</div>
                                            <div className='columna titulos'>No va a casa de: {item.cambioFijoID.direccionNombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))

                        :
                        data.map((item, index) => (
                            <div key={index}>
                                <div key={index} className='print etiqueta'>

                                    <div className='head'>
                                        <img width={'7%'} src={busYkt} alt="" />
                                        <div className='columna titulos'>YESHIVA KETER TORA</div>
                                        <img width={'7%'} src={yeshiba} alt="" />
                                    </div>

                                    {query.get('tipo') == 'reportes' ? <div className='columna titulos'>Reporte al alumno por mala conducta</div> : null}

                                    {query.get('tipo') == 'reportes' ? null : <center className='titulos'>{query.get('tipo') == 'papaRecoge' ? 'Permiso para salir del colegio' : 'Cambio de camión'}</center>}

                                    <div className='fila'>
                                        <div className='columna titulos'>Alumno: {query.get('tipo') == 'reportes' ? item.alumnoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} </div>
                                        <div className='columna titulos'>Grado: {query.get('tipo') == "reportes" ? `${item.alumnoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} ${item.alumnoID.group_code.split('-')[2]}` : `${item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} ${item.alumnoInvitadoID.group_code.split('-')[2]}`}</div>
                                    </div>

                                    <div className='fila'>
                                        {
                                            query.get('tipo') == 'reportes' ? null :
                                                query.get('tipo') == 'papaRecoge' ?
                                                    <div className='columna titulos'>Entregar a: {item.personaRecoge.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                                    :
                                                    query.get('tipo') == 'direccionTemporal' ?
                                                        <>
                                                            <div className='columna titulos'>Debe abordar la ruta: {item.idRutaDestino.numero} {item.idRutaDestino.nombre}</div>
                                                        </>
                                                        :
                                                        query.get('tipo') == 'todos' ?
                                                            <>
                                                                {
                                                                    item.tipo == 'direccionTemporal' ?
                                                                        !item.idRutaDestino ? null :
                                                                            <div className='columna titulos'>Debe abordar la ruta: {item.idRutaDestino.numero} {item.idRutaDestino.nombre}</div>
                                                                        :
                                                                        <div className='columna titulos'>Entregar a: {item.personaRecoge.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                                                }
                                                            </>
                                                            : null

                                        }

                                        {
                                            query.get('tipo') == 'reportes' ?
                                                // item.fecha
                                                <div className='columna titulos'>{`${Number(item.fecha.split('T')[0].split('-')[2])} de ${meses[Number(item.fecha.split('T')[0].split('-')[1]) - 1]} de ${Number(item.fecha.split('T')[0].split('-')[0])}`}</div>
                                                :
                                                query.get('tipo') == 'papaRecoge' ?
                                                    <div className='columna titulos'>{`${item.fecha.split('/')[2]} de ${meses[item.fecha.split('/')[1] - 1]} de ${item.fecha.split('/')[0]}`}</div>
                                                    :
                                                    <>
                                                        {
                                                            item.fecha ?
                                                                <div className='columna titulos'>{`${item.fecha.split('-')[2]} de ${meses[item.fecha.split('-')[1] - 1]} de ${item.fecha.split('-')[0]}`}</div>
                                                                :
                                                                <div className='columna titulos'>{`Cambio fijo de los ${dias[item.dia]}`}</div>
                                                        }
                                                    </>
                                        }

                                    </div>
                                    <br />
                                    {
                                        query.get('tipo') == 'reportes' ?
                                            <>
                                                <div className='columna titulos'>Razón: {item.reporte}</div>
                                                <br />
                                            </>
                                            : query.get('tipo') == 'direccionTemporal' ?
                                                !item.direccionDestino ?
                                                    <>
                                                        <div className='columna titulos'>Se entrega en casa de: {item.alumnoInvitaNombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                                        <br />
                                                    </>
                                                    :
                                                    <>
                                                        <div className='columna titulos'>Dirección de entrega: {item.direccionDestino.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                                        <br />
                                                    </>
                                                : query.get('tipo') == 'todos' ?
                                                    <>
                                                        {
                                                            item.tipo == 'direccionTemporal' ?
                                                                !item.direccionDestino ?
                                                                    <>
                                                                        <div className='columna titulos'>Se entrega en casa de: {item.alumnoInvitaNombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                                                        <br />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className='columna titulos'>Dirección de entrega: {item.direccionDestino.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</div>
                                                                        <br />
                                                                    </>
                                                                :
                                                                <div className='columna titulos'>Razón: {item.reporte}</div>
                                                        }
                                                    </>
                                                    : null
                                    }

                                </div>
                                <div className='noprint division'></div>

                            </div>
                        ))
            }

        </>
    )
}

export default Imprimir